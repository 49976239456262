import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { Box, Text, Button } from 'grommet';
import { FormClose } from 'grommet-icons';

import { AppButton, SimpleModal } from '@Components/Control';

import { AddButton } from './ProductFormComponent';
import { StyledUploadContainer, StyledUploadInput } from './StyledProductFormComponent';


const extractFiles = (data) => data.filter((dt) => dt.added).map((dt) => dt.file);
const renderNewFile = () => ({ id: uniqid(), file: null, added: false });

const MediaUpload = ({
  small,
  singleFile = false,
  maxInputs = 1,
  handleFormValues,
  searchTypeKey,
  authPagesConfig: {
    focusHighlight,
    primaryText,
  },
  inputConfig = null,
}) => {
  const [inputs, setInputs] = React.useState([renderNewFile()]);
  const [showModal, setShowModal] = React.useState(false);

  const addInput = () => {
    const prevInputs = [...inputs];

    if (prevInputs[prevInputs.length - 1].file) {
      const addInputs = [...prevInputs];
      addInputs[addInputs.length - 1].added = true;

      if (!maxInputs || addInputs.length < maxInputs) {
        addInputs.push(renderNewFile());
      }

      setInputs(addInputs);
      const fileValues = extractFiles(addInputs);
      const useValue = singleFile ? fileValues[0] : fileValues;
      handleFormValues(useValue);
    }
  };

  const removeInput = (index) => {
    const prevInputs = [...inputs];
    const remInputs = prevInputs.filter((_, i) => i !== index);
    const { length } = remInputs;

    if (remInputs[length - 1]?.added || length === 0) {
      remInputs.push(renderNewFile());
    }

    setInputs(remInputs);
    const fileValues = extractFiles(remInputs);
    const useValue = singleFile ? null : fileValues;
    handleFormValues(useValue);
  };

  const handleFile = (index, e) => {
    const fileInputs = [...inputs];
    [fileInputs[index].file] = e.currentTarget.files;

    setInputs(fileInputs);
  };

  let acceptedFileTypes = 'image/*, video/*';
  let acceptedFileTypeDisplay = 'image/video';

  if (inputConfig && inputConfig.acceptedFileType) {
    acceptedFileTypes = inputConfig.acceptedFileType.join(',');
    acceptedFileTypeDisplay = inputConfig.acceptedFileType.join('/').replaceAll('/*', '');
  }

  let customHint = null;

  if (inputConfig?.config?.format && inputConfig.config.format[searchTypeKey]) {
    customHint = inputConfig.config.format[searchTypeKey].description;
  }

  return (
    <Box gap="small">
      {customHint && (
        <Button plain onClick={() => setShowModal(true)}>
          <Text size="0.875rem" color={focusHighlight}>
            View CSV File Format
          </Text>
        </Button>
      )}
      {inputs.map(({ id, file, added }, i) => (
        <Box key={id} gap="small">
          <Box direction="row" gap="1rem" width={small ? '100%' : '90%'}>
            <StyledUploadContainer highlight={focusHighlight}>
              <Box fill gap="1rem" align="center" direction="row">
                <Text truncate size="0.875rem" textAlign="end" className={!file ? 'preline' : null}>
                  {file ? file.name : `No ${acceptedFileTypeDisplay} file selected`}
                </Text>
                <AppButton
                  level="xsmall"
                  disabled={added}
                  label="Choose File..."
                  color={focusHighlight}
                />
              </Box>
              <StyledUploadInput
                disabled={added}
                type="file"
                accept={acceptedFileTypes}
                onChange={(e) => handleFile(i, e)}
                highlight={focusHighlight}
              />
            </StyledUploadContainer>
            {added && (
              <Button
                plain
                icon={<FormClose size="1.25rem" color="#636363" />}
                onClick={() => removeInput(i)}
              />
            )}
          </Box>
          {!added && (
            <AddButton disabled={!file} onClick={() => addInput()} highlight={focusHighlight} />
          )}
        </Box>
      ))}
      {showModal && (
        <SimpleModal
          small={small}
          toggleModal={setShowModal}
          modalTitle="CSV File Format"
          closeLabel="Finished"
          textColor={primaryText}
          highlightColor={focusHighlight}
          modalBg="white"
        >
          <Box gap="small" width="100%">
            <Text size="1rem" color={primaryText}>
              {customHint}
            </Text>
          </Box>
        </SimpleModal>
      )}
    </Box>
  );
};

MediaUpload.propTypes = {
  maxInputs: PropTypes.number,
  singleFile: PropTypes.bool,
  searchTypeKey: PropTypes.string.isRequired,
  handleFormValues: PropTypes.func.isRequired,
  authPagesConfig: PropTypes.shape({
    focusHighlight: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
  }).isRequired,
  inputConfig: PropTypes.shape({
    acceptedFileType: PropTypes.arrayOf(PropTypes.string.isRequired),
  }),
  small: PropTypes.bool.isRequired,
};

export default MediaUpload;
