import {
  CYCLOPS_ORGANIZATIONS_DATA_LOADED,
  CYCLOPS_ORGANIZATIONS_DATA_CLEAR,
  CYCLOPS_ACTIVE_ORGANIZATION_SET,
  CYCLOPS_ACTIVE_ORGANIZATION_CLEAR,
} from '../../actions/actionTypes';

const initialState = {
  companyOrganizations: null,
  activeCompanyOrganization: null,
};

const organizations = (state = initialState, action) => {
  switch (action.type) {
    case CYCLOPS_ORGANIZATIONS_DATA_LOADED:
      return {
        ...state,
        companyOrganizations: action.payload.companyOrganizations,
      };

    case CYCLOPS_ORGANIZATIONS_DATA_CLEAR:
      return {
        ...state,
        companyOrganizations: null,
      };

    case CYCLOPS_ACTIVE_ORGANIZATION_SET:
      return {
        ...state,
        activeCompanyOrganization: {
          ...action.payload.activeOrganization,
        },
      };

    case CYCLOPS_ACTIVE_ORGANIZATION_CLEAR:
      return {
        ...state,
        activeCompanyOrganization: null,
      };

    default:
      return state;
  }
};

export default organizations;
