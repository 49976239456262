import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { formInputTypes } from '@Components/configs';

import {
  ProductFormFieldContainer,
  ProductFormDropdownSelect,
  ProductFormCalendarInput,
  ProductFormSingleTextInput,
  ProductFormModalSelect,
  ProductFormMultipleTextInputs,
  ProductFormTextAreaInput,
  ProductFormMediaUpload,
  ProductFormMultipleTagInputs,
  ProductFormMultipleSuggestionInputs,
  ProductFormMultipleGroupInputs,
} from '@Components/Partial/Product/NewSearch';


const FormComponentSelector = ({
  fieldType,
  fieldTitle,
  description = null,
  inputConfig = null,
  searchTypeKey,
  inputKey,
  resetKey,
  options = null,
  value = null,
  handleFormValues,
  authPagesConfig,
  small,
  today,
  useNextDay,
  minStartDate = null,
  limit = null,
  charLimit = null,
  selectable = false,
}) => {
  const {
    singleUrlInput,
    multipleUrlInput,
    singleTextInput,
    multipleTextInput,
    multiLinedTextInput,
    multipleGroupInput,
    textArea,
    datetimeEntry,
    multipleDatetimeEntry,
    radioSelect,
    multiSelect,
    tagsInput,
    suggestionsInput,
    singleMediaUpload,
    multipleMediaUpload,
  } = formInputTypes;

  switch (fieldType) {
    case singleUrlInput:
    case singleTextInput:
      return (
        <ProductFormSingleTextInput
          key={resetKey}
          focusHighlight={authPagesConfig.focusHighlight}
          small={small}
          value={value || ''}
          handleFormValues={(val) => handleFormValues(val)}
        />
      );

    case multipleUrlInput:
    case multipleTextInput:
      return (
        <ProductFormMultipleTextInputs
          key={resetKey}
          authPagesConfig={authPagesConfig}
          inputConfig={inputConfig}
          searchTypeKey={searchTypeKey}
          small={small}
          value={value || []}
          handleFormValues={(val) => handleFormValues(val)}
          maxInputs={limit}
        />
      );

    case multipleGroupInput:
      return (
        <ProductFormMultipleGroupInputs
          key={resetKey}
          authPagesConfig={authPagesConfig}
          inputConfig={inputConfig}
          searchTypeKey={searchTypeKey}
          inputType="sets"
          small={small}
          value={value || ''}
          handleFormValues={(val) => handleFormValues(val)}
          today={today}
          useNextDay={useNextDay}
          maxInputs={limit}
          charLimit={charLimit}
        />
      );

    case multiLinedTextInput:
      return (
        <ProductFormMultipleGroupInputs
          key={resetKey}
          authPagesConfig={authPagesConfig}
          inputConfig={inputConfig}
          searchTypeKey={searchTypeKey}
          inputType="textArea"
          small={small}
          value={value || ''}
          handleFormValues={(val) => handleFormValues(val)}
          today={today}
          maxInputs={limit}
          charLimit={charLimit}
        />
      );

    case textArea:
      return (
        <ProductFormTextAreaInput
          key={resetKey}
          authPagesConfig={authPagesConfig}
          small={small}
          handleFormValues={(val) => handleFormValues(val)}
          limit={limit}
          charLimit={charLimit}
        />
      );

    case datetimeEntry:
      return (
        <ProductFormCalendarInput
          key={uniqid()}
          authPagesConfig={authPagesConfig}
          inputConfig={inputConfig}
          searchTypeKey={searchTypeKey}
          small={small}
          value={value || undefined}
          handleFormValues={(val) => handleFormValues(val)}
          today={today}
          useNextDay={useNextDay}
          minStartDate={inputKey === 'start_date_and_time' ? null : minStartDate}
        />
      );

    case multipleDatetimeEntry:
      return (
        <ProductFormMultipleGroupInputs
          key={resetKey}
          inputType="date"
          authPagesConfig={authPagesConfig}
          inputConfig={inputConfig}
          searchTypeKey={searchTypeKey}
          small={small}
          value={value || undefined}
          options={options}
          handleFormValues={(val) => handleFormValues(val)}
          today={today}
          useNextDay={useNextDay}
          maxInputs={limit}
        />
      );

    case radioSelect:
      return (
        <ProductFormDropdownSelect
          key={resetKey}
          authPagesConfig={authPagesConfig}
          searchTypeKey={searchTypeKey}
          small={small}
          value={value}
          handleFormValues={(val) => handleFormValues(val)}
          simpleOptions={options}
          selectable={selectable}
        />
      );

    case multiSelect:
      return (
        <ProductFormModalSelect
          key={resetKey}
          authPagesConfig={authPagesConfig}
          inputConfig={inputConfig}
          searchTypeKey={searchTypeKey}
          small={small}
          options={options}
          value={value}
          handleFormValues={(val) => handleFormValues(val)}
          fieldTitle={fieldTitle}
          description={description}
          maxInputs={limit}
        />
      );

    case tagsInput:
    case suggestionsInput:
      if (options && options.length > 0) {
        return (
          <ProductFormMultipleSuggestionInputs
            key={resetKey}
            authPagesConfig={authPagesConfig}
            inputConfig={inputConfig}
            searchTypeKey={searchTypeKey}
            small={small}
            handleFormValues={(val) => handleFormValues(val)}
            options={options}
          />
        );
      }

      return (
        <ProductFormMultipleTagInputs
          key={resetKey}
          authPagesConfig={authPagesConfig}
          inputConfig={inputConfig}
          searchTypeKey={searchTypeKey}
          small={small}
          handleFormValues={(val) => handleFormValues(val)}
        />
      );

    case singleMediaUpload:
      return (
        <ProductFormMediaUpload
          singleFile
          key={resetKey}
          authPagesConfig={authPagesConfig}
          inputConfig={inputConfig}
          searchTypeKey={searchTypeKey}
          small={small}
          maxInputs={1}
          handleFormValues={(val) => handleFormValues(val)}
        />
      );

    case multipleMediaUpload:
      return (
        <ProductFormMediaUpload
          key={resetKey}
          authPagesConfig={authPagesConfig}
          inputConfig={inputConfig}
          searchTypeKey={searchTypeKey}
          small={small}
          handleFormValues={(val) => handleFormValues(val)}
          maxInputs={limit}
        />
      );

    default:
      return null;
  }
};

FormComponentSelector.propTypes = {
  fieldType: PropTypes.string.isRequired,
  fieldTitle: PropTypes.string.isRequired,
  searchTypeKey: PropTypes.string.isRequired,
  inputKey: PropTypes.string.isRequired,
  resetKey: PropTypes.string.isRequired,
  description: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired),
  value: PropTypes.any,
  inputConfig: PropTypes.objectOf(PropTypes.any),
  today: PropTypes.instanceOf(Date).isRequired,
  useNextDay: PropTypes.bool.isRequired,
  minStartDate: PropTypes.string,
  limit: PropTypes.number,
  charLimit: PropTypes.number,
  selectable: PropTypes.bool,
  authPagesConfig: PropTypes.shape({
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
  }).isRequired,
  handleFormValues: PropTypes.func.isRequired,
  small: PropTypes.bool.isRequired,
};

const DynamicFormRenderer = ({
  fieldData: {
    key,
    fieldType,
    fieldName,
    description,
    values,
    config,
    limit,
    charLimit,
    selectable,
  },
  resetKey,
  searchTypeKey = null,
  handleFormValues,
  formValues,
  authPagesConfig,
  today,
  useNextDay,
  minStartDate = null,
  small,
}) => (
  <ProductFormFieldContainer
    small={small}
    fieldTitle={fieldName}
    description={description}
    primaryText={authPagesConfig.primaryText}
    hintText={authPagesConfig.hintText}
    borderColor={authPagesConfig.navBorder}
    limit={limit}
    charLimit={charLimit}
  >
    <FormComponentSelector
      searchTypeKey={searchTypeKey}
      resetKey={resetKey}
      inputKey={key}
      fieldType={fieldType}
      small={small}
      authPagesConfig={authPagesConfig}
      value={formValues[key]}
      options={values}
      inputConfig={config}
      handleFormValues={(val) => handleFormValues(val, key)}
      description={description}
      fieldTitle={fieldName}
      limit={limit}
      charLimit={charLimit}
      selectable={selectable}
      today={today}
      useNextDay={useNextDay}
      minStartDate={minStartDate}
    />
  </ProductFormFieldContainer>
);

DynamicFormRenderer.propTypes = {
  fieldData: PropTypes.shape({
    key: PropTypes.string.isRequired,
    config: PropTypes.objectOf(PropTypes.any),
    fieldName: PropTypes.string.isRequired,
    fieldType: PropTypes.string.isRequired,
    description: PropTypes.string,
    selectable: PropTypes.bool,
    values: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]).isRequired),
    limit: PropTypes.number,
    charLimit: PropTypes.number,
  }).isRequired,
  resetKey: PropTypes.string.isRequired,
  searchTypeKey: PropTypes.string,
  today: PropTypes.instanceOf(Date).isRequired,
  useNextDay: PropTypes.bool.isRequired,
  minStartDate: PropTypes.string,
  authPagesConfig: PropTypes.shape({
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
  }).isRequired,
  handleFormValues: PropTypes.func.isRequired,
  formValues: PropTypes.objectOf(PropTypes.any).isRequired,
  small: PropTypes.bool.isRequired,
};

export default DynamicFormRenderer;
